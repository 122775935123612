<template>
  <div
    v-if="notification"
    class="h-auto w-auto px-6 py-5 bg-white flex justify-center items-center gap-5 rounded-lg shadow-lg"
  >
    <span
      class="flex-none w-3 h-3 rounded-full"
      :class="notification.read ? 'bg-white' : 'bg-secondary'"
    ></span>
    <div class="flex-grow grid cols-span-1 text-left">
      <h4 class="font-bold text-xl text-gray-600">{{ notification.title }}</h4>
      <p class="my-px">
        {{ notification.msg }}
      </p>
      <p class="text-sm text-gray-500">
        {{ $DateTime.fromISO(notification.date).toFormat('dd/L/yyyy') }}
      </p>
    </div>
    <span class="flex-none w-4 h-4 bg-gray-300 rounded-full"></span>
  </div>
</template>

<script>
export default {
  props: ['notification'],
}
</script>

<style></style>
