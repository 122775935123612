<template>
  <div>
    <div class="flex flex-col gap-3">
      <div class="my-3">
        <h2 class="my-0 text-left font-bold text-3xl text-gray-500">Hoy</h2>
        <p class="text-left text-2xl text-gray-400">
          {{ today.toFormat('dd/L/yyyy') }}
        </p>
      </div>
      <NotificationCard
        v-for="(notification, index) in todayNotifications"
        :class="notification.path ? 'cursor-pointer' : ''"
        :notification="notification"
        :key="index + 'todayNotifications'"
        @click.native="setRead(notification)"
      />
      <div class="my-3">
        <h2 class="my-0 text-left font-bold text-3xl text-gray-500">Ayer</h2>
        <p class="text-left text-2xl text-gray-400">
          {{ yesterday.toFormat('dd/L/yyyy') }}
        </p>
      </div>
      <NotificationCard
        class="cursor-pointer"
        v-for="(notification, index) in yesterdayNotification"
        :class="notification.path ? 'cursor-pointer' : ''"
        :notification="notification"
        :key="index + 'yesterdayNotification'"
        @click.native="setRead(notification)"
      />
      <div class="my-3">
        <h2 class="my-0 text-left font-bold text-3xl text-gray-500">
          Una semana antes
        </h2>
        <p class="text-left text-2xl text-gray-400">
          {{ pastWeek.toFormat('dd/L/yyyy') }}
        </p>
      </div>
      <NotificationCard
        class="cursor-pointer"
        v-for="(notification, index) in pastWeekNotificactions"
        :class="notification.path ? 'cursor-pointer' : ''"
        :notification="notification"
        :key="index + 'pastWeekNotificactions'"
        @click.native="setRead(notification)"
      />
    </div>
  </div>
</template>

<script>
import NotificationCard from '@/components/UI/Notifications/NotificationCard'
import { getAuth } from 'firebase/auth'
import { getDatabase, ref, child, get, set } from 'firebase/database'

export default {
  components: {
    NotificationCard,
  },
  data() {
    return {
      today: '',
      yesterday: '',
      pastWeek: '',
      todayNotifications: [],
      yesterdayNotification: [],
      pastWeekNotificactions: [],
    }
  },
  created() {
    const date = this.$DateTime.now()
    const today = date
    const yesterday = date.minus({ days: 1 })
    const pastWeek = date.minus({ days: 9 })
    this.today = today
    this.yesterday = yesterday
    this.pastWeek = pastWeek
    this.fetchNotification()
  },
  methods: {
    async fetchNotification() {
      const auth = getAuth()
      const user = auth.currentUser
      const dbRef = ref(getDatabase())
      let response = await get(child(dbRef, `users/${user.uid}`))
      if (response.exists()) {
        let notifications = Object.values(response.val())
        this.filterNotifications(notifications)
        this.readNotifications()
      }
    },
    filterNotifications(notifications) {
      notifications.forEach((notification) => {
        let notificationDate = this.$DateTime.fromISO(notification.date)
        // Today
        if (
          notificationDate.hasSame(this.today, 'day') &&
          notificationDate.hasSame(this.today, 'month')
        ) {
          this.todayNotifications.unshift(notification)
          return
        }
        // Yesterday
        if (
          notificationDate.hasSame(this.yesterday, 'day') &&
          notificationDate.hasSame(this.yesterday, 'month')
        ) {
          this.yesterdayNotification.unshift(notification)
          return
        }
        // PastWeek
        let past = this.yesterday.minus({ days: 1 })
        if (notificationDate > this.pastWeek && notificationDate < past) {
          this.pastWeekNotificactions.unshift(notification)
          return
        }
      })
    },
    readNotifications() {
      const notifications = [
        ...this.todayNotifications,
        ...this.yesterdayNotification,
        ...this.pastWeekNotificactions,
      ]

      const auth = getAuth()
      const user = auth.currentUser
      const db = getDatabase()
      notifications.forEach((notification) => {
        if (notification.read) return
        set(ref(db, 'users/' + user.uid + '/' + notification.id), {
          ...notification,
          read: true,
        })
      })
    },
    setRead(notification) {
      const auth = getAuth()
      const user = auth.currentUser
      const db = getDatabase()
      if (!notification.read) {
        set(ref(db, 'users/' + user.uid + '/' + notification.id), {
          ...notification,
          read: true,
        })
      }

      if (notification.path) {
        this.$router.push(notification.path)
      }
    },
  },
}
</script>

<style></style>
